body {
	margin: 0;
	font-family: -apple-system, 'Inter', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	--bg-color: #eff3f8;
	--main-color: #0099fa;
	--main-gardient-color: rgba(0, 153, 250, 0.8);
	/* --main-color: #002c77;
  --main-gardient-color: #3F4FA1; */
	--secondary-color: #f0caa3;
	--secondary-gardient-color: #f0caa3;
	--action-color: #c060a1;
	--action-gardient-color: #ed6d92;
}

.dx-widget input,
.dx-widget textarea {
	font-family: -apple-system, 'Inter', sans-serif !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .blurring.dimmed.dimmable > :not(.dimmer):not(.dx-overlay-wrapper):not(.dx-overlay-content):not(.dx-overlay) {
  -webkit-filter: blur(25px) grayscale(0.1);
  filter: blur(25px) grayscale(0.1)!important;
} */
.blurring.dimmed.dimmable > :not(.dimmer) {
	filter: unset !important;
}

/* .dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer{
  opacity: 0.7!important;
} */

.dx-datagrid .dx-row > td {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	font-size: 13px;
	line-height: 16px;
	background-color: #fff;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
	border-bottom: 1px solid #e0e0e0;
	padding-top: 7px !important;
	padding-bottom: 7px !important;
	line-height: 16px;
	font-weight: 700 !important;
	font-size: 12px;
}

.p-is-active {
	background: linear-gradient(to bottom, #32cd32 0%, #3caa62 100%);
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
	background: linear-gradient(to bottom, #32cd32 0%, #3caa62 100%);
	color: white !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
	border-top: 1px solid #f5f5f5 !important;
}

.dx-header-row > td:first-of-type {
	border-right: 1px solid #e0e0e0;
}

.dx-header-row > td {
	border-right: 1px solid #e0e0e0 !important;
}

.dx-datagrid .dx-header-filter:before {
	font-size: 10px;
}

.dx-datagrid .dx-header-filter,
.dx-datagrid .dx-sort-down,
.dx-datagrid .dx-sort-up,
.dx-datagrid-container .dx-header-filter,
.dx-datagrid-container .dx-sort-down,
.dx-datagrid-container .dx-sort-up {
	line-height: 10px !important;
}

.dx-datagrid-filter-panel {
	padding: 6px 10px !important;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 500;
}

.dx-datagrid-filter-panel .dx-icon-filter {
	color: rgba(0, 0, 0, 0.54) !important;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
	height: 20px !important;
	line-height: 20px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
	height: 20px !important;
	line-height: 0px !important;
}

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
	height: 20px !important;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
	padding: 0px !important;
}

/* .dx-datagrid-table-fixed {
	background-color: #fafafa !important;
} */

.master-detail-caption {
	padding: 0 0 5px 10px;
	font-size: 14px;
	font-weight: bold;
}

.header-title {
	padding-left: 20px !important;
}

.side-nav-outer-toolbar {
	background-color: #fff;
}

.dx-widget {
	font-family: -apple-system, 'Inter', sans-serif !important;
}

.brand-header {
	background-color: #0078d4 !important;
	color: #fff !important;
}

.brand-header > .dx-toolbar-items-container {
	height: 48px !important;
}

.user-info .user-name {
	color: #fff !important;
}

.header-component .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
	color: #fff !important;
}

.system-other-list {
	width: 400px !important;
	max-width: 400px !important;
}

.padding-1rem {
	padding: 1rem;
}

.width-400 {
	width: 400px;
}

.padding-top-1rem {
	padding-top: 1rem;
}

.padding-left-1rem {
	padding-left: 1rem;
}

.system-other {
	text-align: center;
	border-radius: 10px;
	color: black !important;
}

.system-other > a {
	color: black !important;
}

.system-other:hover {
	background-color: #f2f2f2;
}

.system-other-img {
	margin-left: auto;
	margin-right: auto;
	width: 40px;
	box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}

.system-other-name {
	margin-top: 0.5rem;
}

.text-align-center {
	text-align: center;
}

.width-100-p {
	width: 100%;
}

.width-50-p {
	width: 40%;
}

.app-name {
	font-size: 20px;
	font-weight: 400;
	color: #333;
	white-space: pre-line;
	pointer-events: all;
}

.homescreen {
	overflow: hidden;
}

.p-fluid {
	width: 100%;
}

.p-white-color {
	color: white !important;
}

.bg-grey {
	background-color: hsla(0, 0%, 74.9%, 0.15);
}

.bg-background {
	background-color: #fff !important;
	/* margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px; */
	padding-left: 20px;
	padding-right: 10px;
}

.padding-2rem {
	padding: 2rem;
}

.padding-top-2rem {
	padding-top: 2rem;
}

.padding-top-1rem {
	padding-top: 1rem;
}

.padding-left-2rem {
	padding-left: 2rem;
}

.padding-bottom-0 {
	padding-bottom: 0px !important;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

.padding-right-2rem {
	padding-right: 2rem;
}

.hide {
	display: none;
}

.p-prim-col {
	/* background: linear-gradient(to bottom, #4fc5fa 0%, #0f75f5 100%); */
	background: linear-gradient(to bottom, #32cd32 0%, #3caa62 100%);
	background-size: 100%;
	border: 0;
	box-shadow: inset 0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgba(0, 0, 0, 0.3);
	color: white !important;
}

.padding-top-25 {
	padding-top: 25px;
}

.ui.modal > .actions button {
	margin-right: 5px;
}

.ui.modal > .header {
	font-family: unset !important;
	padding: 8px !important;
	padding-left: 1.5rem !important;
}

.ui.modal > .header > p {
	font-weight: 500 !important;
	font-size: 15px !important;
}

.ui.modal > .content {
	/* padding-bottom: 40px !important; */
	margin-bottom: -10px;
}

.dx-editor-outlined {
	border-radius: 8px !important;
	background-color: rgba(0, 0, 0, 0.04) !important;
	border: none !important;
	box-shadow: none !important;
}

.dx-editor-outlined.dx-state-focused {
	box-shadow: inset 0 0 0 1px #03a9f4 !important;
}

.text-mute {
	color: rgba(0, 0, 0, 0.5);
}

.ui.inline.loader.active,
.ui.inline.loader.visible {
	margin-left: 5px;
}

.margin-left-5 {
	margin-left: 5px;
}

a {
	cursor: pointer;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: unset !important;
}

.loading-login {
	width: 100px;
	height: 100px;
	margin-top: 45vh;
	margin-left: auto;
	margin-right: auto;
}

.dx-field-label {
	color: black !important;
	width: 100% !important;
}

.dx-texteditor-input {
	color: black !important;
	border-radius: 10px !important;
}

/* .table-noborder-left .dx-datagrid-rowsview,
.dx-datagrid-headers,
.dx-datagrid-filter-panel {
  border-left: 0px !important;
}

.table-noborder-left .dx-datagrid-filter-panel {
  border-bottom: 0px !important;
} */

.body-container .dx-drawer-content {
	/* background-color: #fafafa; */
	background-color: #fff;
}

/* .dx-rtl .dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl .dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-texteditor.dx-editor-filled .dx-placeholder:before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder:before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 8px !important;
} */

.icon.edit {
	color: #3689e6;
}

.icon.trash {
	color: #c6262e;
}

.dx-data-row.dx-selection .icon.edit {
	color: white;
}

.dx-data-row.dx-selection .icon.trash {
	color: white;
}

.text-align-right {
	text-align: right;
}

.dx-toolbar-button .dx-item-content {
	padding-left: unset !important;
}

.dx-field-label-full {
	font-size: 13px;
	cursor: default;
	align-self: center;
	padding-right: 12px;
	color: black !important;
	float: left;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.student_search .input {
	width: 100%;
}

.icon.color-green {
	color: #3caa62;
}

.text-danger {
	color: #dc3545;
}

.text-success {
	color: #3caa62;
}

.cmd-disabled {
	opacity: 0.3;
	cursor: not-allowed !important;
}

.dx-tagbox.dx-editor-filled .dx-tag-container,
.dx-tagbox.dx-editor-outlined .dx-tag-container {
	padding: 0px !important;
	min-height: 32px !important;
}

.dx-tag {
	margin-left: 5px !important;
	margin-top: 6px !important;
}

.dx-tag-content {
	padding: 2px 30px 2px 10px !important;
}

.PrimaryDropDownButton {
	background: linear-gradient(to bottom, #32cd32 0%, #3caa62 100%);
	box-shadow: inset 0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgb(0 0 0 / 30%);
	border-radius: 0.3rem;
	height: 26.38px !important;
}

.PrimaryDropDownButton .dx-list-item-icon {
	color: black !important;
}

.PrimaryDropDownButton .dx-button-content {
	color: white !important;
}

.PrimaryDropDownButton .fas {
	color: white !important;
}

.PrimaryDropDownButton .dx-icon-spindown {
	color: white !important;
}

.PrimaryDropDownButton .dx-button-text {
	text-transform: unset !important;
	font-family: -apple-system, 'Inter', sans-serif !important;
	font-size: 1rem;
	line-height: unset;
}

.PrimaryDropDownButton .dx-button-content {
	padding: 0.3rem 1.1rem !important;
}

.PrimaryDropDownButton .dx-buttongroup-item {
	border: 0;
}

.header-toolbar {
	height: 48px !important;
}

.header-toolbar .dx-toolbar-items-container {
	height: 48px !important;
}

.header-toolbar .fas {
	color: white;
}

.p-btn {
	margin: 0px;
}

.dx-toolbar-item-content h3 {
	margin-bottom: 0px;
}

.form-group {
	margin-top: 10px;
}
